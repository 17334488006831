import React from 'react';
// import { useNavigate } from 'react-router-dom';

function MenuTreino() {
  
  // const navigate = useNavigate()
  // const Voltar = () => {
  //   navigate('/home')
  // }
    
  return (
    <div className=''>
    
          <nav className=''>
            <ul className=' my-3 flex flex-col justify-start lg:justify-start shadow-2xl rounded-lg items-center text-sm p-0 max-w-lg mx-auto'>

              {/* <img src={ImageMenu} className='w-[180px] h-[160px] lg:w-[120px] lg:h-[100px] p-0 m-0' alt="" /> */}
            </ul>
          </nav>
    
    </div>
  );

}

export default MenuTreino;

import React, { useEffect, useState } from "react";
import "../Styles/App.css";
import HamburgerMenu from "../Menu/HamburgerMenu";
import ChatContainer from "../ChatContainer/ChatContainer";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";
import ErrorCard from "../Error/ErrorCardHome";
import LoadInicial from "../LoadingComponent/LoadInicial";
//import Step from '../../img/FacilitaNutri-Step.svg'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { user, adicionarErro, retornaTicketUsado} = UserAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [suporte, setSuporte] = useState(false);
  const [TicketsUsados, setTicketsUsados] = useState(false)
  const { retornaTicket } = UserAuth();
  const [answer, setAnswer] = useState([]);
  const [userTickets, setUserTickets] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };



  useEffect(() => {
    retornaTicket()
      .then(async (tickets) => {
        if (tickets !== null) {
          setUserTickets(tickets);
        } else {
          setUserTickets("Nenhum ticket disponível");
        }
      })
      .catch((error) => {
        setUserTickets("Erro ao obter os tickets");
      });
  }, [retornaTicket]);

  useEffect(() => {
      retornaTicketUsado()
      .then((tickets) => {
        if (tickets !== null) {
          if(tickets === 0 || tickets === undefined){
              setTicketsUsados(false)
            }else{
              setTicketsUsados(true)
            }
        } else {
          console.log("Nenhum ticket disponível");
        }

      })
      .catch((error) => {
        console.log("Erro ao obter os tickets");
      });
  }, [retornaTicketUsado]);

  const wpp = () => {
    if (userTickets === "DietaTreino" || userTickets === "DietaNormal") {
      setSuporte(!suporte);
    } else {
      const numeroum = "5524999375062";
      const numerodois = "5524992178767";

      const numeros = [];
      function selecionarNumeroAleatoriamente() {
        numeros.push(numeroum);
        numeros.push(numerodois);

        const indiceAleatorio = Math.floor(Math.random() * numeros.length);
        return numeros[indiceAleatorio];
      }

      const whatsappURL = `https://api.whatsapp.com/send?phone=${selecionarNumeroAleatoriamente()}`;

      // Abre uma nova janela ou guia para iniciar a conversa no WhatsApp
      window.open(whatsappURL, "_blank");
    }
  };

 

  const sendRequest = async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/GerarTreino`,
        { data: data, uid: user.uid }
      );
      setAnswer(result.data.answer);
      return;
    } catch (error) {
      // Adicionando mais detalhes ao erro, se disponíve
        console.error("Erro na requisição:", error.message);
        adicionarErro("Erro ao enviar a requisição: " + error.message);
      }
    }


  // Exemplo de uso:

  return (
    <div className="App pb-5">
      {TicketsUsados === true ? (
        <LoadInicial onClose={() => setTicketsUsados(false)} />
      ) : (
        <>
          <header className="App-header p-3">
            <HamburgerMenu
              isOpen={isMenuOpen}
              toggleMenu={toggleMenu}
              tickets={userTickets}
            />
          </header>
          <br></br>
          <main className="App-main">
            <br />
            <ChatContainer
              sendRequest={sendRequest}
              answer={answer}
            />
          </main>
          {suporte && (
            <ErrorCard
              title={"Monte o Seu Treino"}
              message={
                "Preencha as informações novamente, selecione seus musculos e depois clique em montar treino"
              }
              onClose={wpp}
            />
          )}
          <br />
          <br />
          <div></div>
        </>
      )}
    </div>
  );
}

export default Home;